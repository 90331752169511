<template>
    <div>
        <div class="container container-checkin">
            <div class="form-row">
                <div class="form-group col-md-7 form-row">
                    <div class="padding-15 form-row">
                        <div class="col-md-8 commands" v-if="leg">
                            <h6 class="text-center margin-none">
                            {{leg.FlightNumber}} - {{formatDate(leg.DepartureDate)}} - {{getTime(leg.STD)}}
                            {{leg.DepartureStation}}/{{leg.ArrivalStation}} - {{leg.LegStatus}}
                            Puerta: {{leg.Gate}}
                            </h6>
                             <editedby :current="leg"></editedby>
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-primary" @click="boarding = false"
                            :disabled="!boarding">CHECKIN</button>
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="btn btn-primary" @click="boarding = true"
                            :disabled="boarding">EMBARQUE</button>
                        </div>
                        <div class="form-group col-md-12 h-75" v-if="list" v-show="!processing">
                            <v-client-table :data="datalist" :columns="columnsNames" :options="options" refs="table" >
                            </v-client-table>
                        </div>
                        <div v-if="processing" class="form-group col-md-12 padding-15">
                            <img class="loading-gif" src="@/img/loading.gif">
                        </div>
                        <div class="form-group col-md-12 mt-3" v-if="boarding">
                            <h3 class="text-center">******* EMBARCANDO *******</h3>
                        </div>
                        <div class="form-group col-md-12 mt-3" v-if="!boarding">
                            <h3 class="text-center">******* CHECKIN *******</h3>
                        </div>

                        <div class="form-group col-md-11">
                            <input class="form-control mr-sm-2 checkin-command" type="search" :placeholder="placeholder"
                                aria-label="Search" v-model="command" :disabled="sending" refs="command" id="command">
                        </div>
                        <div class="form-group col-md-1">
                            <a @click="showHelp">
                                <font-awesome-icon icon="question-circle" />
                            </a>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="form-row"  v-if="help">
                                <div class="col-md-4 commands"><b>cls:</b> Limpiar busqueda</div>
                                <div class="col-md-4 commands"><b>u:</b> Actualizar lista</div>
                                <div class="col-md-4 commands"><b>cf:</b> Cerrar vuelo</div>
                                <div class="col-md-4 commands"><b>of:</b> Abrir vuelo</div>
                                <div class="col-md-4 commands"><b>cp:</b> Cierre pendiente</div>
                                <div class="col-md-4 commands"><b>call:</b> Checkear Todos los Pasajeros</div>
                                <div class="col-md-4 commands"><b>rall:</b> Descheckear Todos los Pasajeros</div>
                                <div class="col-md-4 commands"><b>ball:</b> Embarcar Todos los Pasajeros</div>
                                <div class="col-md-4 commands"><b>uball:</b> Descheckear Todos los Pasajeros</div>
                                <div class="col-md-4 commands"><b>Nr:</b> Seleccionar Pasajero</div>
                                <div class="col-md-4 commands"><b>c:</b> Chequear pasajero</div>
                                <div class="col-md-4 commands"><b>c/w:</b> Chequear pasajero/asignar ventana</div>
                                <div class="col-md-4 commands"><b>c/a:</b> Chequear pasajero/asignar pasillo</div>
                                <div class="col-md-4 commands"><b>c/12a:</b> Chequear pasajero/asignar 12A</div>
                                <div class="col-md-4 commands"><b>rc:</b> Remover ceckin</div>
                                <div class="col-md-4 commands"><b>sa:</b> Asignar asiento aleatorio</div>
                                <div class="col-md-4 commands"><b>sa/12a:</b> Asignar asiento 12a</div>
                                <div class="col-md-4 commands"><b>sa/w:</b> Asignar asiento ventana</div>
                                <div class="col-md-4 commands"><b>sa/a:</b> Asignar asiento pasillo</div>
                                <div class="col-md-4 commands"><b>rs:</b> Remover asiento</div>
                                <div class="col-md-4 commands"><b>bp:</b> Imprimir boarding</div>
                                <div class="col-md-4 commands"><b>bs/1a:</b> Bloquear Asiento 1a</div>
                                <div class="col-md-4 commands"><b>us/1a:</b> Desbloquear Asiento 1a</div>
                                <!--div class="col-md-4 commands"><b>xhelp:</b> cerrar ayuda</div-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-3 form-row padding-15">
                    <div class="col-md-12 card pax-card h-75">
                        <checkinpaxinfo :currentPax="currentPax" @checkin="checkPassenger" @uncheck="removeCheckin"
                            @board="boardPassenger" @unboard="unboardPassenger" :boarding="boarding" :bookingPax="bookingPax"
                            @print="printBoardings" @printInf="printBoarding(true)" :sending="sending"></checkinpaxinfo>
                    </div>
                    <div class="col-md-12 card pax-card h-25">
                        <checkinflightinfo :summary="summary" :paxlist="list"></checkinflightinfo>
                    </div>
                </div>
                <div class="form-group col-md-2 px-1 py-3">
                  <selectinput fieldname="flihgt_key" :fieldOptions="flightOptions"
                    :current_value.sync="inventoryLegID" ></selectinput>

                    <seatmap :seatMap="seatMap" :paxList="list" :currentPax="currentPax"
                    @assign="assignMapSeat" @showFree="showFree"></seatmap>
                </div>
            </div>
        </div>

        <selectvalue v-if="printerList" @save="setPrinter" @close="closeSelectPrinter"
          text="Elegir Impresora" :fieldOptions="printerList"></selectvalue>

        <boardingpass v-if="boardingPass" @close="closeBoardingPass" :rows="boardingPass"></boardingpass>
        <checkincomments v-if="showComments" @close="closeComments" :comments="comments"></checkincomments>


    </div>
</template>

<script>
import '@/css/app.css';
;
import selectvalue from '@/components/tools/SelectValue';
import seatmap from '@/components/checkin/SeatMap';
import checkinpaxinfo from '@/components/checkin/CheckinPaxInfo';
import checkinflightinfo from '@/components/checkin/CheckinFlightInfo';
import boardingpass from '@/components/checkin/BoardingPass';
import checkincomments from '@/components/checkin/CheckinComments';
import selectinput from '@/components/tools/SelectInput';
import editedby from '@/components/tools/EditedBy';
import { mapState } from 'vuex';
import Vue from 'vue/dist/vue.esm'
import {ClientTable, Event} from 'vue-tables-2'
let theme;
Vue.use(ClientTable, [theme = 'bootstrap5']);
var moment = require('moment');
export default {
  name: 'checkinflight',
  components: {

    seatmap,
    checkinpaxinfo,
    checkinflightinfo,
    selectvalue,
    boardingpass,
    checkincomments,
    selectinput,
    editedby
  },
  props: ['inventoryleg'],
  data  () {
    return {
        leg: null,
        processing: false,
        sending: false,
        command: null,
        list: null,
        searchValue: null,
        seatMap: null,
        seatMaps: {},
        help: true,
        seatMapRows: [],
        currentPax: null,
        summary: null,
        electron: null,
        currentPrinter: null,
        printerList: null,
        boarding: false,
        boardingPass: false,
        comments: null,
        showComments: false,
        SSRValue: null,
        flight: null,
        free: null,
        inventoryLegID: null,
        checkin_actions: true,
        printDirect: null,
        printerName: null,
        LiftStatusValues: {
            0: 'NO CHEQUEADO',
            1: 'CHECKIN',
            2: 'EMBARCADO',
            3: 'NO SHOW',
            4: 'ENDOSADO'
        },
        printRows: [],
        columnsNames: ['RowNr', 'Passenger', 'ArrivalStation','LiftStatus', 'RecordLocator', 'Fare', 'BoardingSequence', 'UnitDesignator', 'Message'],
        options: {
            filterable: false,
            perPageValues: [7],
            columnsClasses: {'Passenger': 'checkin-name'},
            perPage: 7,
                headings: {},
                destroyEventBus: true,
                texts: app.vueTableText(),
                templates: {},
                skin: 'table table-striped  table-sm table-bordered',
            },


    }
  },
  mounted: async function() {
    if (!this.inventoryleg) {
        this.$router.push('checkin');
    }
    this.checkin_actions = app.canAccess(this.user, 'api','POST', '/checkin/checkin_actions/', true);
    this.inventoryLegID = this.inventoryleg.InventoryLegID;
    this.options.templates = this.getTemplates;
    this.options.headings = this.getHeadings;
    this.printDirect = localStorage.getItem("printDirect");
    this.printerName = localStorage.getItem("printerName");
    this.getPassengers();
    this.getSeatMapsByLegs();
    this.printRows = await app.get('/api/checkin/get_print_format');
    this.leg = this.inventoryleg;
    this.flight = await app.get('/api/flight/' + this.inventoryleg.FlightId)
    for (let i=1; i<36; i++) {
        this.seatMapRows.push(i);
    }

    try {
        this.electron = window.require("electron");
    } catch (e) {
        this.electron = null;
    }
    if (this.electron) {
        let currentPrinter = await this.getCurrentPrinter();
        if (currentPrinter) {
            this.currentPrinter = currentPrinter;
            return;
        }
    }
    let self = this;
    Event.$on('vue-tables.row-click', function (data) {
        if (data.event.target.innerHTML=='***') {
            self.comments = data.row.Comments;
            self.showComments = true;
            self.$nextTick(function () {
                $('#checkincomments').modal('show')
            })
            return;
        }
        self.currentPax = data.row;
        self.setSeatMap();
    });
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
    myfields () {
        return [
            {name: 'RowNr', label: 'Nr'},
            {name: 'LiftStatus', label: 'Estado'},
            {name: 'ArrivalStation', label: 'Dest.'},
            {name: 'BoardingSequence', label: 'BN'},
            {name: 'RecordLocator', label: 'Código'},
            {name: 'Fare', label: 'Tarifa'},
            {name: 'UnitDesignator', label: 'Asiento'},
            {name: 'Passenger', label: 'Pasajero'},
            {name: 'Message', label: 'MSG'},
        ]
    },
    getTemplates () {
        let res = {}
        let self = this;
        for (let fieldname of self.columnsNames) {
            res[fieldname] = function(h, row) {
                //return <a href="#"> {self.getDisplayValue(row, fieldname)} </a> ;
                return self.getDisplayValue(row, fieldname);
            }

        }
        return res;
    },
    getHeadings () {
        let res = {};
        for (let f of this.myfields) {
            if (f.label) {
                res[f.name] = f.label;
            }
        }
        return res;
    },
    datalist  () {
        let self = this;
        if (self.searchValue || self.SSRValue) {
            let list;
            if (self.searchValue) {
                let values = self.searchValue.split(' ');
                list = _.filter(self.list, (r) => {
                    for (let value of values){
                        let found = false;
                        let re = new RegExp(value, 'i')
                        for (let f of self.columnsNames) {
                            let displayValue = self.getDisplayValue(r, f);
                            if (!displayValue) displayValue = r[f];
                            if (!displayValue) continue
                            if (displayValue) displayValue = displayValue.toString();
                            if (displayValue){
                                let m = displayValue.match(re)
                                if (m) found = true;
                            }
                        }
                        if (!found) return false;
                    }
                    return true;
                })
                if (list.length==0) {
                    alert('Pasajero no encontrado');
                    this.searchValue = null;
                    list = self.list;
                }
            } else if (self.SSRValue) {

                if (self.SSRValue.toLowerCase()=='qn') {
                    list = _.filter(self.list, (r) => {
                        return r.LiftStatus==1;
                    })
                } else if (self.SSRValue.toLowerCase()=='n') {
                    list = _.filter(self.list, (r) => {
                        return r.LiftStatus==3;
                    })
                } else if (self.SSRValue.toLowerCase()=='qb') {
                    list = _.filter(self.list, (r) => {
                        return r.LiftStatus==2;
                    })
                } else {
                    list = _.filter(self.list, (r) => {
                        if (r.Passenger.DocNumber && r.Passenger.DocNumber.toUpperCase()==self.SSRValue.toUpperCase()) {
                            return true;
                        }
                        for (let fee of r.SegmentFees) {
                            if (fee.FeeCode && fee.FeeCode.toUpperCase()==self.SSRValue.toUpperCase()) {
                                return true;
                            }
                        }
                        for (let ssr of r.SSRs) {
                            if (ssr.SSRCode && ssr.SSRCode.toUpperCase()==self.SSRValue.toUpperCase()) {
                                return true;
                            }
                        }
                        for (let bag of r.Baggages) {
                            let f = _.find(bag.LegBaggages, function(b) {
                                return b.SegmentID==r.SegmentID && b.LegNumber==r.LegNumber && b.BaggageStatus!=2;
                            })
                            if (f) {
                                if (bag.OSTag && self.SSRValue.toUpperCase()==bag.OSTag.toUpperCase()) return true;
                            }
                        }
                    })
                }
            }
            let c = 1;
            for (let row of list) {
                row.RowNr = c;
                c++;
            }
            return list;
        }
        let c = 1;
        for (let row of self.list) {
            row.RowNr = c;
            c++;
        }
        return self.list;
    },
    placeholder () {
        if (this.sending) return 'Procesando ...';
        if (!this.searchValue) return "Buscar pasajero o Ingresar Comando";
        if (this.searchValue && !this.currentPax) return "Ingresar Nro de Pasajero";
        return "Ingresar comando";
    },
    bookingPax () {
        let res = '';
        if (this.currentPax) {
            let b = _.filter(this.list, (p)=>p.Booking.RecordLocator==this.currentPax.Booking.RecordLocator)
            return b.length;
        }
        return res;
    },
    flightOptions  (){
        let res = [];
        for (let key in this.seatMaps) {
            let label = key;
            if (this.flight) {
                let leg = _.find(this.flight.InventoryLegs, (f)=>f.InventoryLegID==key);
                if (leg) {
                    label = leg.DepartureStation + '-' + leg.ArrivalStation;
                }

            }
            res.push({label: label, value: key})
        }
        return {flihgt_key: res};
    }

  },
  methods: {
    getSeatMap: async function() {
        let res = await app.get('/api/checkin/seat_map', {data: JSON.stringify([this.inventoryleg.InventoryLegID])})
        if (res) {
            this.seatMap = res;
        }
    },
    getSeatMapsByLegs: async function() {
        let res = await app.get('/api/checkin/seat_maps_by_leg/' + this.inventoryleg.FlightId)
        if (res) {
            this.seatMap = res[this.inventoryleg.InventoryLegID];
            this.seatMaps = res;
        }
    },

    getPassengers: async function() {
        this.processing = true;
        let data = {
            InventoryLegID: this.inventoryleg.InventoryLegID,
            DepartureStation: this.inventoryleg.DepartureStation,
        }
        let res = await app.get('/api/checkin/flight_list', data)
        if (res) {
            this.setPassengerList(res.PassengerLegs)
            this.summary = res.Summary;
            this.processing = false;
        }
    },
    setPassengerList (PassengerLegs) {
        let c = 1;
        for (let row of PassengerLegs) {
            row.RowNr = c;
            c++;
        }
        this.list = PassengerLegs.sort(function (a, b) {
            if (a.Passenger.LastName + a.Passenger.FirstName > b.Passenger.LastName + b.Passenger.FirstName) {
                return 1;
            } else {
                return -1
            }
        });
    },
    getDisplayValue (row, fieldname) {
        let liftstatus = {
            0: 'NO CHEQUEADO',
            1: 'CHECKIN',
            2: 'EMBARCADO',
            3: 'NO SHOW',
            4: 'ENDOSADO'
        }
        if (fieldname=='LiftStatus') return liftstatus[row.LiftStatus];
        if (fieldname=='ArrivalStation') return row.Segment.ArrivalStation;
        if (fieldname=='Passenger')  {
            if (!row.Passenger.LastName || !row.Passenger.FirstName) return 'SIN NOMINAR';
            let paxName = row.Passenger.LastName + ', ' + row.Passenger.FirstName;
            if (row.Passenger.MiddleName) paxName += ' ' + row.Passenger.MiddleName;
            paxName += ' ' + row.Passenger.PaxType;
            if (!row.Passenger.Infant) return paxName;
            let infantName = row.Infants[0].LastName + ', ' + row.Infants[0].FirstName;
            if (row.Infants[0].MiddleName) infantName += ' ' + row.Infants[0].MiddleName;
            return paxName + ' (INF: ' + infantName + ')';
        }
        if (fieldname=='RecordLocator') return row.Booking.RecordLocator;
        if (fieldname=='Fare') return row.Segment.ClassOfService + ' (' + row.ProductClass.Name + ')';
        if (fieldname=='Message') {
            if (row.Comments.length>0) return '***';
            return '';
        }
        return row[fieldname];
    },
    evenListener: async function(e){
        if (e.keyCode == 13) {
            if (this.boarding) {
                this.boardingModeCommands();
            } else {
                this.checkinModeCommands();
            }
            this.command = null;
        }
    },
    boardingModeCommands () {
        let pax;
        if (this.command && this.command.length>20 &&
            this.command.substring(0, 2)=='M1') {
            let nr = parseInt(this.command.substring(157,167));
            if (nr) {
                pax = _.find(this.list, function(r) {
                    if (r.SegmentID==nr) return true;
                });
            }
        } else if (!isNaN(this.command)) {
            let nr = parseInt(this.command);
            if (nr) {
                pax = _.find(this.list, function(r) {
                    if (r.SegmentID==nr) return true;
                });
                if (!pax) {
                    pax = _.find(this.list, function(r) {
                        if (r.BoardingSequence==nr) return true;
                    });
                }
            }
        } else if (this.command.split(' ').length>5){
            let list = this.command.split(' ');
            let nr = parseInt(list[0]);
            if (nr) {
                pax = _.find(this.list, function(r) {
                    if (r.SegmentID==nr) return true;
                });
            }
        } else {
            pax = _.find(this.list, (r)=> r.UnitDesignator==this.command.toUpperCase());
        }
        if (pax) {
            this.currentPax = pax;
            this.setSeatMap();
            if (this.currentPax.LiftStatus==2) {
                alert('Pasajero ya embarcado');
                return;
            }
            if (this.currentPax.LiftStatus!=1) {
                alert('Pasajero No Chequeado');
                return;
            }
            this.boardPassenger();
        } else {
            if (this.command.toLowerCase()=='cls') {
                this.currentPax = null;
                this.searchValue = '';
                this.SSRValue = null;

            } else if (this.command && this.command.toLowerCase()=='u') {
                this.currentPax = null;
                this.searchValue = '';
                this.SSRValue = null;
                this.udpateFlightData();
            } else if (this.command && this.command.toLowerCase()=='cf') {
                this.closeFlight();
            } else if (this.command && this.command.toLowerCase()=='cp') {
                this.closePending();
            } else if (this.command && this.command.toLowerCase()=='of') {
                this.openFlight();
            } else if (this.command && this.command.toLowerCase()=='edifact_print') {
                this.edifactPrint();
            } else if (this.command && this.command.toLowerCase()=='uball') {
                this.unboardPassengerAll();
            } else if (this.command && this.command.toLowerCase()=='ball') {
                this.boardPassengerAll();
            } else if (this.command && this.command.toLowerCase().substring(0, 2)=='rm' && this.command[2]=='/') {
                this.removeMessage();
            } else if (this.command && this.command.toLowerCase().substring(0, 2)=='am' && this.command[2]=='/') {
                this.addMessage();
            } else if (this.command && this.command.toLowerCase().substring(0, 2)=='rm' && this.command[2]=='/') {
                this.removeMessage();
            } else if (this.command && this.command[0]=='.' && this.command.length>1) {
                this.searchSSR();
            } else {
                this.searchValue = this.command;
                this.SSRValue = null;
            }
        }
    },
    checkinModeCommands () {
        if (!isNaN(this.command) && this.command.substring(0, 1)!='.') {
            let pax = _.find(this.datalist, (r)=> r.RowNr==parseInt(this.command))
            if (pax) {
                this.currentPax = pax;
                this.setSeatMap();
            }
        } else if (this.command.toLowerCase()=='help') {
            this.help = true;
        } else if (this.command.toLowerCase()=='xhelp') {
            //this.help = false;
        } else if (this.command.toLowerCase()=='cls') {
            this.currentPax = null;
            this.searchValue = '';
            this.SSRValue = null;
        } else if (this.command.toLowerCase()=='brd') {
            this.gotToBoard();
        } else if (this.command && this.command.toLowerCase()=='cf') {
            this.closeFlight();
        } else if (this.command && this.command.toLowerCase()=='rall') {
            this.removeCheckinAll();
        } else if (this.command && this.command.toLowerCase()=='rsall') {
            this.removeSeatAll();
        } else if (this.command && this.command.toLowerCase()=='call') {
            this.checkPassengerAll();
        } else if (this.command && this.command.toLowerCase()=='cp') {
            this.closePending();
        } else if (this.command && this.command.toLowerCase()=='edifact') {
            this.edifactUpload();
        } else if (this.command && this.command.toLowerCase()=='edifact_print') {
            this.edifactPrint();
        } else if (this.command && this.command.toLowerCase()=='of') {
            this.openFlight();
        } else if (this.command && this.command.toLowerCase()=='end') {
            this.endorsePassenger();
        } else if (this.command && this.command.toLowerCase()=='rend') {
            this.unEndorsePassenger();
        } else if (this.command && this.command.toLowerCase().substring(0, 2)=='bs' && this.command[2]=='/') {
            this.blockSeat();
        } else if (this.command && this.command.toLowerCase().substring(0, 4)=='gate' && this.command[4]=='/') {
            this.setGate();
        } else if (this.command && this.command.toLowerCase().substring(0, 3)=='std' && this.command[3]=='/') {
            this.setSTD();
        } else if (this.command && this.command.toLowerCase().substring(0, 3)=='dob' && this.command[3]=='/') {
            this.setDOB();
        } else if (this.command && this.command.toLowerCase().substring(0, 2)=='am' && this.command[2]=='/') {
            this.addMessage();
        } else if (this.command && this.command.toLowerCase().substring(0, 2)=='rm' && this.command[2]=='/') {
            this.removeMessage();
        } else if (this.command && this.command.toLowerCase().substring(0, 2)=='us' && this.command[2]=='/') {
            this.unblockSeat();
        } else if (this.command && this.command[0]=='.' && this.command.length>1) {
            this.searchSSR();
        } else if (this.command && this.command.toLowerCase()=='u') {
            this.currentPax = null;
            this.searchValue = '';
            this.SSRValue = null;
            this.udpateFlightData();
        } else if (this.command && this.currentPax && this.command.toLowerCase().substring(0, 2)=='sa' && this.command[2]=='/') {
            this.assignSeat();
        } else if (this.command && this.currentPax && this.command.toLowerCase()=='sa') {
            this.assignSeat();
        } else if (this.command && this.currentPax && this.command.toLowerCase()=='bp') {
            this.printBoardings();
        } else if (this.command && this.currentPax && this.command.toLowerCase()=='rs') {
            this.removeSeat();
        } else if (this.command && this.currentPax && this.command.toLowerCase()=='rc') {
            this.removeCheckin();
        } else if (this.command && this.currentPax && this.command.toLowerCase().substring(0, 1)=='c' && this.command[1]=='/') {
            this.checkPassenger(this.command);
        } else if (this.command && this.currentPax && this.command.toLowerCase()=='c') {
            this.checkPassenger(this.command);
        } else if (this.command && this.currentPax && this.command.toLowerCase().substring(0, 2)=='ab' && this.command[2]=='/') {
            this.addBaggage();
        } else if (this.command && this.currentPax && this.command.toLowerCase().substring(0, 2)=='rb' && this.command[2]=='/') {
            this.removeBaggage();
        } else {
            this.searchValue = this.command;
            this.SSRValue = null;
        }
    },
    setSeatMap () {
        for (let option of this.flightOptions.flihgt_key) {
            let stations = option.label.split('-');
            let departureStation = stations[0];
            let arrivalStation = stations[stations.length-1];
            if (departureStation==this.currentPax.Segment.DepartureStation &&
                arrivalStation==this.currentPax.Segment.ArrivalStation) {
                    this.inventoryLegID = option.value;
                    return;
                }
        }
    },
    checkComand (c) {
        if (this.command.indexOf(c)>-1) return true;
        return false;
    },
    assignSeat: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        let c = this.command.split('/');
        let data = {
            SegmentID: this.currentPax.SegmentID,
            LegNumber: this.currentPax.LegNumber,
            RecordLocator: this.currentPax.Booking.RecordLocator,
            FlightId: this.inventoryleg.FlightId,
            free: this.free,
        }
        let endpoint = 'ramdom_seat';
        if (c.length>1) {
            if (c[1].toUpperCase()=='W' || c[1].toUpperCase()=='A' || c[1].toUpperCase()=='M') {
                data.ColumnType = c[1].toUpperCase();
            } else {
                data.UnitDesignator = c[1].toUpperCase();
                endpoint = 'assign_seat';
            }
        }
        let res = await app.postJSON('/api/checkin/' + endpoint, data, this);
        if (res) {
            this.updateData(res);
            this.free = null;
        }
    },
    assignMapSeat: async function(r, c){
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        if (!this.currentPax) return;
        if (this.boarding) return;
        let data = {
            SegmentID: this.currentPax.SegmentID,
            LegNumber: this.currentPax.LegNumber,
            RecordLocator: this.currentPax.Booking.RecordLocator,
            UnitDesignator: r + c,
            FlightId: this.inventoryleg.FlightId,
            free: this.free
        }
        let res = await app.postJSON('/api/checkin/assign_seat', data, this);
        if (res) {
            this.updateData(res);
            this.free = null;
        }
    },
    removeSeat: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        let data = {
            SegmentID: this.currentPax.SegmentID,
            LegNumber: this.currentPax.LegNumber,
            RecordLocator: this.currentPax.Booking.RecordLocator,
            FlightId: this.inventoryleg.FlightId
        }
        let res = await app.postJSON('/api/checkin/remove_seat', data, this);
        if (res) {
            this.updateData(res);
        }
    },
    checkPassenger: async function(command) {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        let data = {
            SegmentID: this.currentPax.SegmentID,
            LegNumber: this.currentPax.LegNumber,
            RecordLocator: this.currentPax.Booking.RecordLocator,
            InventoryLegID: this.currentPax.InventoryLegID,
            FlightId: this.inventoryleg.FlightId
        }
        if (command) {
            let c = command.split('/');
            if (c.length>1) {
                if (c[1].toUpperCase()=='W' || c[1].toUpperCase()=='A' || c[1].toUpperCase()=='M') {
                    data.ColumnType = c[1].toUpperCase();
                } else {
                    data.UnitDesignator = c[1].toUpperCase();
                }
            }
        }
        let res = await app.postJSON('/api/checkin/checkin', data, this);
        if (res) {
            this.updateData(res);
        }
    },
    removeCheckin: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        let data = {
            SegmentID: this.currentPax.SegmentID,
            LegNumber: this.currentPax.LegNumber,
            InventoryLegID: this.currentPax.InventoryLegID
        }
        let res = await app.postJSON('/api/checkin/uncheck', data, this);
        if (res) {
            this.updateData(res);
        }
    },
    removeCheckinAll: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        if (!app.canAccess(this.user, 'api','POST', '/checkin/uncheck_all', false)) {
            alert('Acción no permitida');
            return;
        }
        let data = {
            InventoryLegID: this.inventoryleg.InventoryLegID,
            FlightId: this.inventoryleg.FlightId,
        }
        let res = await app.postJSON('/api/checkin/uncheck_all', data, this);
        if (res) {
            this.summary = res.Summary;
            this.setPassengerList(res.PassengerLegs)
            this.seatMap = Object.assign({}, res.SeatMap[this.inventoryLegID]);
            this.seatMaps = res.SeatMap;
        }
    },
    removeSeatAll: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        if (!app.canAccess(this.user, 'api','POST', '/checkin/uncheck_all', false)) {
            alert('Acción no permitida');
            return;
        }
        let data = {
            InventoryLegID: this.inventoryleg.InventoryLegID,
            FlightId: this.inventoryleg.FlightId,
        }
        let res = await app.postJSON('/api/checkin/remove_seats_all', data, this);
        if (res) {
            this.summary = res.Summary;
            this.setPassengerList(res.PassengerLegs)
            this.seatMap = Object.assign({}, res.SeatMap[this.inventoryLegID]);
            this.seatMaps = res.SeatMap;
        }
    },

    checkPassengerAll: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida 1');
            return;
        };
        if (!app.canAccess(this.user, 'api','POST', '/checkin/check_all', false)) {
            alert('Acción no permitida');
            return;
        }
        let data = {
            FlightId: this.inventoryleg.FlightId,
            InventoryLegID: this.inventoryleg.InventoryLegID,
        }
        let res = await app.postJSON('/api/checkin/check_all', data, this);
        if (res) {
            this.summary = res.Summary;
            this.setPassengerList(res.PassengerLegs)
            this.seatMap = Object.assign({}, res.SeatMap[this.inventoryLegID]);
            this.seatMaps = res.SeatMap;

        }
    },

    unboardPassengerAll: async function() {
        if (this.sending) return;
        let data = {
            InventoryLegID: this.inventoryleg.InventoryLegID,
        }
        if (!app.canAccess(this.user, 'api','POST', '/checkin/unboard_all', false)) {
            alert('Acción no permitida');
            return;
        }
        let res = await app.postJSON('/api/checkin/unboard_all', data, this);
        if (res) {
            this.summary = res.Summary;
            this.setPassengerList(res.PassengerLegs)
        }
    },
    boardPassengerAll: async function() {
        if (this.sending) return;
        let data = {
            InventoryLegID: this.inventoryleg.InventoryLegID,
        }
        if (!app.canAccess(this.user, 'api','POST', '/checkin/board_all', false)) {
            alert('Acción no permitida');
            return;
        }
        let res = await app.postJSON('/api/checkin/board_all', data, this);
        if (res) {
            this.summary = res.Summary;
            this.setPassengerList(res.PassengerLegs)
        }
    },

    addBaggage: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        let c = this.command.split('/');
        if (c.length==3) {
            if (c[1].substring(0, 3).toUpperCase()!=this.currentPax.Segment.ArrivalStation) {
                alert('Destino Incorrecto');
                return;
            }

            let data = {
                Weight: c[2],
                BaggageTag: c[1].toUpperCase(),
                SegmentID: this.currentPax.SegmentID,
                LegNumber: this.currentPax.LegNumber,
                InventoryLegID: this.currentPax.InventoryLegID
            }
            let res = await app.postJSON('/api/checkin/add_baggage', data, this);
            if (res) {
                this.updateData(res);
            }
        } else {
            alert("comando incorrecto");
        }
    },
    removeBaggage: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        let c = this.command.split('/');
        let bagIdx = c[1];
        if (isNaN(bagIdx)) {
            alert("comando incorrecto");
            return;
        }
        if (c.length==2) {
            let ids = this.getBaggageIDs(this.currentPax, bagIdx);
            let data = {
                BaggageIDs: ids,
                SegmentID: this.currentPax.SegmentID,
                InventoryLegID: this.currentPax.InventoryLegID
            }
            let res = await app.postJSON('/api/checkin/remove_baggage', data, this);
            if (res) {
                this.currentPax.Baggages = res.Baggages;
                this.currentPax = Object.assign({}, this.currentPax);
                if (res.Summary) this.summary = res.Summary;
            }

        } else {
            alert("comando incorrecto");
        }

    },

    updatePassenger (pax) {
        let p = _.findIndex(this.list, function(r) {
            return r.SegmentID==pax.SegmentID && r.LegNumber==pax.LegNumber;
        })
        if (p>-1) {
            pax.RowNr = this.list[p].RowNr;
            this.list[p] = pax;
            this.list = Object.assign([], this.list)
        }
    },
    updateData (res) {
        if (res.SeatMap && res.SeatMap[this.inventoryLegID]) {
            this.seatMap = Object.assign({}, res.SeatMap[this.inventoryLegID]);
            this.seatMaps = res.SeatMap;
        }
        this.currentPax = Object.assign({}, res.PassengerLeg);
        this.updatePassenger(res.PassengerLeg);
        if (res.Summary) this.summary = res.Summary;
    },
    getSeatClass (c, r) {
        let seat = _.find(this.seatMap, function(s) {
            let unit = r + c;
            return unit==s.UnitDesignator;
        });
        if (seat && this.currentPax && seat.UnitDesignator==this.currentPax.UnitDesignator) return 'seat seat-current-pax';
        if (seat && seat.Status=='AVAILABLE') return 'seat seat-available';
        if (seat) return 'seat seat-not-available';
        return 'non-seat';
    },
    getBaggageTags (pax) {
        let res = []
        for (let bag of pax.Baggages) {
            let f = _.find(bag.LegBaggages, function(b) {
                return b.SegmentID==pax.SegmentID && b.LegNumber==pax.LegNumber && b.BaggageStatus!=2;
            })
            if (f) {
                res.push(bag.OSTag)
            }
        }
        return res.join(', ');
    },
    getBaggageIDs (pax, i) {
        let res = []
        for (let bag of pax.Baggages) {
            let f = _.find(bag.LegBaggages, function(b) {
                return b.SegmentID==pax.SegmentID && b.LegNumber==pax.LegNumber && b.BaggageStatus!=2;
            })
            if (f) {
                res.push(bag.BaggageID)
            }
        }
        if (i<1) {
            alert('comando incorrecto');
            return
        }
        if (i>res.length) {
            alert('comando incorrecto');
            return
        }
        return [res[i-1]];
    },
    udpateFlightData: async function(){
        this.getPassengers();
        this.getSeatMapsByLegs();
        let res = await app.get('/api/inventoryleg/' + this.leg.InventoryLegID);
        if (res) this.updateInventoryLeg(res);
    },
    blockSeat: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        let c = this.command.split('/');
        let data = {
            InventoryLegID: this.inventoryleg.InventoryLegID,
        }
        if (c.length>1) {
            data.UnitDesignator = c[1].toUpperCase();
        }
        let res = await app.postJSON('/api/checkin/block_seat', data, this);
        if (res) {
            this.seatMap = Object.assign({}, res);
        }
    },
    addMessage: async function() {
        if (this.sending) return;
        let c = this.command.split('/');
        let data = {
            BookingID: this.currentPax.Booking.BookingID,
            SegmentID: this.currentPax.SegmentID,
            LegNumber: this.currentPax.LegNumber,
        }
        if (c.length>1) {
            data.Message = c[1];
            let res = await app.postJSON('/api/checkin/add_message', data, this);
            if (res) {
                this.updatePassenger(res);
            }
        } else {
            alert("comando incorrecto");
        }
    },
    removeMessage: async function() {
        if (this.sending) return;
        let c = this.command.split('/');
        let data = {
            BookingID: this.currentPax.Booking.BookingID,
            SegmentID: this.currentPax.SegmentID,
            LegNumber: this.currentPax.LegNumber,
        }
        if (c.length>1) {
            data.MessageNr = parseInt(c[1]);
            let res = await app.postJSON('/api/checkin/remove_message', data, this);
            if (res) {
                this.updatePassenger(res);
            }
        } else {
            alert("comando incorrecto");
        }
    },

    setDOB: async function() {
        if (this.sending) return;
        let c = this.command.split('/');
        let data = {
            BookingID: this.currentPax.Booking.BookingID,
            SegmentID: this.currentPax.SegmentID,
            LegNumber: this.currentPax.LegNumber,
            PassengerID: this.currentPax.Passenger.PassengerID,
        }
        if (c.length>1) {
            data.DOB = c[1];
            if (data.DOB.length!=8) {
                alert('Fecha Incorrecta');
                return;
            }
            if (isNaN(data.DOB)) {
                alert('Fecha Incorrecta');
                return;
            }
            let res = await app.postJSON('/api/checkin/set_dob', data, this);
            if (res) {
                this.currentPax = Object.assign({}, res);
                this.updatePassenger(res);
            }
        } else {
            alert("comando incorrecto");
        }
    },

    setGate: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };

        let c = this.command.split('/');
        let data = {
            InventoryLegID: this.inventoryleg.InventoryLegID
        }
        if (c.length>1) {
            data.Gate = c[1];
            let res = await app.postJSON('/api/checkin/set_gate', data, this);
            if (res) {
                if (res) this.updateInventoryLeg(res);
            }
        } else {
            alert("comando incorrecto");
        }
    },

    setSTD: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };

        let c = this.command.split('/');
        let data = {
            InventoryLegID: this.inventoryleg.InventoryLegID
        }
        if (c.length>1) {
            data.STD = c[1];
            let res = await app.postJSON('/api/checkin/set_std', data, this);
            if (res) {
                if (res) this.updateInventoryLeg(res);
            }
        } else {
            alert("comando incorrecto");
        }
    },


    searchSSR () {
        this.SSRValue = this.command.replace('.', '');
    },
    unblockSeat: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        let c = this.command.split('/');
        let data = {
            InventoryLegID: this.inventoryleg.InventoryLegID,
        }
        if (c.length>1) {
            data.UnitDesignator = c[1].toUpperCase();
        }
        let res = await app.postJSON('/api/checkin/unblock_seat', data, this);
        if (res) {
            this.seatMap = Object.assign({}, res);
        }
    },
    closeFlight: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        this.processing = true;
        let data = {
            InventoryLegID: this.inventoryleg.InventoryLegID,
        }
        let res = await app.postJSON('/api/checkin/close_flight', data, this);
        if (res) {
            this.updateInventoryLeg(res.InventoryLeg);
            this.setPassengerList(res.PassengerLegs)
            this.summary = res.Summary;
            this.processing = false;
        }
    },
    openFlight: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        this.processing = true;
        let data = {
            InventoryLegID: this.inventoryleg.InventoryLegID,
        }
        let endpoint = 'open_flight';
        if (this.inventoryleg.LegStatus=='CLOSE_PENDING') endpoint = 'open_flight_pending'
        let res = await app.postJSON('/api/checkin/' + endpoint, data, this);
        if (res) {
            this.updateInventoryLeg(res.InventoryLeg);
            this.setPassengerList(res.PassengerLegs)
            this.summary = res.Summary;
            this.processing = false;
        }
    },
    edifactPrint: async function() {
        this.edifact('print');
    },
    edifactUpload: async function() {
        this.edifact('upload');
    },
    edifact: async function(mode) {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        let data = {
            InventoryLegID: this.inventoryleg.InventoryLegID,
        }
        let res = await app.postJSON('/api/flight/edifact/' + mode, data, this);
        if (res) {
            console.log(res);
        }
    },
    closePending: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        this.processing = true;
        let data = {
            InventoryLegID: this.inventoryleg.InventoryLegID,
        }
        let res = await app.postJSON('/api/checkin/close_pending', data, this);
        if (res) {
            this.updateInventoryLeg(res);
            this.processing = false;
        }
    },
    updateInventoryLeg (leg) {
        this.leg = leg;
        //this.$emit("update:inventoryleg", leg);
    },
    formatDate (d) {
        return moment(d).locale('es').format('ddd DD MMM')
    },
    printBoardings: async function() {
        if (this.currentPax.LiftStatus!=1 && this.currentPax.LiftStatus!=2) {
            alert('Pasajero no chequeado');
            return;
        }
        this.printBoarding();
        if ((this.electron || this.printDirect=='2') && this.currentPax.Passenger.Infant) {
            this.printBoarding(true)
        }
    },
    printBoarding: async function(infant) {
        let finalRows = [];
        for (let row of this.printRows) {
            let res = [];
            for (let i in row.values) {
                let field = row.values[i];
                if (field.label) {
                    res.push(field.label);
                }
                if (field.data) {
                    let keys = field.data.split('.');
                    let obj = this.currentPax;
                    if (keys[0]=='Passenger' && infant && this.currentPax.Infants[0]) {
                        obj = {Passenger: this.currentPax.Infants[0]};
                    }
                    for (let key of keys) {
                        obj = obj[key];
                    }
                    if (field.format=='date') {
                        obj = moment(obj).locale('es').format('DDMMMYY');
                    }
                    res.push(obj);
                }
            }
            if (row.barcode) {
                if (this.printDirect=='2') {
                    let code = 'M1';
                    let paxName = this.currentPax.Passenger.LastName + '/' + this.currentPax.Passenger.FirstName
                        + (this.currentPax.Passenger.MiddleName? this.currentPax.Passenger.MiddleName: '');
                    paxName = paxName.substring(0, 20);
                    paxName = paxName.padStart(20, " ");
                    code += paxName;
                    code += ' ';
                    code += this.currentPax.Booking.RecordLocator.padStart(7, " ");
                    code += this.currentPax.Segment.DepartureStation;
                    code += this.currentPax.Segment.ArrivalStation;
                    code += 'O4';
                    code += ' ';
                    code += this.inventoryleg.FlightNumber.padStart(4, "0");
                    code += ' ';
                    code += moment(this.inventoryleg.DepartureDate).format('DDD').padStart(3, "0");
                    code += ' ';
                    code += this.currentPax.UnitDesignator.padStart(4, "0");
                    code += this.currentPax.BoardingSequence.toString().padStart(4, "0");
                    code = code.padEnd(158, " ");
                    code += this.currentPax.Segment.SegmentID;
                    row.value = this.noTilde(code);
                    console.log(row.value)
                } else {
                    row.value = res.join(' ');
                }
            } else {
                row.value = res.join('  ');
            }
            finalRows.push(row);
        }

        if (this.electron) {
            if (!this.currentPrinter) {
                let currentPrinter = await this.getCurrentPrinter();
                if (currentPrinter) {
                    this.currentPrinter = currentPrinter;
                } else {
                    alert('Impresora no configurada');
                    return;
                }
            }
            var ipcRenderer = this.electron.ipcRenderer;
            ipcRenderer.on('print-data-result', function (event, store) {
                if (store) {
                    alert(store);
                }
            });
            ipcRenderer.send('print-data', {values: finalRows, printerName: this.currentPrinter});
        } else if (this.printDirect=='2') {
            if (!this.printerName) {
                alert('Impresora no configurada');
                return;
            }
            let a = await fetch('http://localhost:3000/print',
                {
                    method: 'post',
                    body: JSON.stringify( {values: finalRows, printerName: this.printerName}),
                    headers:{'Content-Type': 'application/json'}
                }
            );
        } else {
            let boardingPass = [];
            for (let row of finalRows) {
                boardingPass.push(row);
            }
            this.boardingPass = boardingPass;
            this.$nextTick(function() {
                $('#boarding-pass').modal('show');
            })
        }
    },
    noTilde  (s) {
        if (s.normalize != undefined) {
            s = s.normalize ("NFKD");
        }
        s = s.replace(/æ/g, "ae");
        s = s.replace(/[\'`´]/g, "");
        return s.replace(/[\u0300-\u036F]/g, "");
    },
    closeBoardingPass () {
        this.boardingPass = null;
        $('#boarding-pass').modal('hide');
    },
    getPrinterList: async function() {
        if (this.electron) {
            return await app.getPrinterList(this.electron);
        } else {
            alert('Comando no disponible para versión web');
        }
    },
    getCurrentPrinter: async function() {
        if (this.electron) {
            return await app.getCurrentPrinter(this.electron);
        } else {
            alert('Comando no disponible para versión web');
        }
    },
    setCurrentPrinter: async function(printerName) {
        if (this.electron) {
            let res = await app.setCurrentPrinter(this.electron, printerName)
            if (res) {
                alert(res)
                return;
            }
            this.currentPrinter = printerName;
        } else {
            alert('Comando no disponible para versión web');
        }
    },
    showHelp (){
        //this.help = !this.help;
    },
    setPrinter (printerName) {
        this.printerList = null;
        $('#selectValue').modal('hide');
        this.setCurrentPrinter(printerName)
    },
    closeSelectPrinter () {
        this.printerList = null;
        $('#selectValue').modal('hide');
    },
    gotToBoard () {
        this.boarding = true;
    },
    boardPassenger: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        let data = {
            SegmentID: this.currentPax.SegmentID,
            LegNumber: this.currentPax.LegNumber,
            InventoryLegID: this.currentPax.InventoryLegID
        }
        let res = await app.postJSON('/api/checkin/board_pax', data, this);
        if (res) {
            this.updateData(res);
        }
    },
    unboardPassenger: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        let data = {
            SegmentID: this.currentPax.SegmentID,
            LegNumber: this.currentPax.LegNumber,
            InventoryLegID: this.currentPax.InventoryLegID
        }
        let res = await app.postJSON('/api/checkin/unboard_pax', data, this);
        if (res) {
            this.updateData(res);
        }
    },
    endorsePassenger: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        let data = {
            SegmentID: this.currentPax.SegmentID,
            LegNumber: this.currentPax.LegNumber,
            InventoryLegID: this.currentPax.InventoryLegID
        }
        let res = await app.postJSON('/api/checkin/endorse', data, this);
        if (res) {
            this.updateData(res);
        }
    },
    unEndorsePassenger: async function() {
        if (this.sending) return;
        if (!this.checkin_actions) {
            alert('Acción no permitida');
            return;
        };
        let data = {
            SegmentID: this.currentPax.SegmentID,
            LegNumber: this.currentPax.LegNumber,
            InventoryLegID: this.currentPax.InventoryLegID
        }
        let res = await app.postJSON('/api/checkin/unendorse', data, this);
        if (res) {
            this.updateData(res);
        }
    },


    closeComments () {
        this.showComments = false;
        this.comments = null;
        $('#checkincomments').modal('hide')
    },
    getTime (d) {
        return moment(d).format("HH:mm");
    },
    showFree: async function() {
        this.free = true;
        let res = await app.get('/api/checkin/seat_maps_by_leg/' + this.inventoryleg.FlightId, {free: true})
        if (res) {
            this.seatMap = res[this.inventoryleg.InventoryLegID];
            this.seatMaps = res;
        }
    },
  },
  watch: {
      inventoryLegID () {
            this.seatMap = this.seatMaps[this.inventoryLegID]
      },
      boarding () {
        if (this.boarding) {
            this.columnsNames = ['Passenger', 'ArrivalStation','LiftStatus', 'RecordLocator', 'Fare', 'BoardingSequence', 'UnitDesignator', 'Message'];
        } else {
            this.columnsNames = ['RowNr', 'Passenger', 'ArrivalStation','LiftStatus', 'RecordLocator', 'Fare', 'BoardingSequence', 'UnitDesignator', 'Message'];
        }
      },
      sending  () {
        this.$nextTick(function() {
            $('#command')[0].focus();
        })
      }
  },
  beforeDestroy() {
        document.removeEventListener('keydown',this.evenListener);
        Event.$off('vue-tables.row-click');
  },
  created (){
        document.addEventListener("keydown", this.evenListener);
  }
}
</script>

