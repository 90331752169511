<template>
  <home endpoint="edifactmessage" :params="params"></home>
</template>

<script>
import home from '@/components/tools/Index'
export default {
name: 'edifactmessage',
components: {
  home,
},
data () {
  return {
    params: {
      primaryKey: 'id',
      fields: [
        {name: 'id', label: 'ID', readonly: true},
        {name: 'InventoryLegID', label: 'Leg', readonly: true},
        {name: 'MessageType', label: 'Tipo de Mensaje', readonly: true},
        {name: 'Message', label: 'Mensaje', readonly: true, editor: 'textarea'},
        {name: 'Status', label: 'Estado', readonly: true},
        {name: 'Error', label: 'Error', readonly: true, editor: 'textarea'},
        {name: 'CreatedAt', label: 'Fecha', readonly: true},
        {name: 'DepartureStation', label: 'Origen', readonly: true},
        {name: 'ArrivalStation', label: 'Destino', readonly: true},
        {name: 'FlightNumber', label: 'Vuelo', readonly: true},
        {name: 'FlightDate', label: 'Fecha', readonly: true}
      ],
      title: 'Edifact',
      title2: 'Edifact'
    }
  }
},
}
</script>
